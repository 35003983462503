import React from "react";

const Buttons = () => {
    return (
        <>
            <a href="#">
                <button className="btn">Hire Me!</button>
            </a>
        </>
    );
};

export default Buttons;
